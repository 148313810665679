/* eslint-disable remix-react-routes/use-link-for-routes */
type TLoginWrapper = {
	children: React.ReactNode
}

export default function LoginWrapper({ children }: TLoginWrapper) {
	return (
		<div className="flex h-screen flex-col-reverse lg:flex-row">
			<div className="h-2/3 lg:flex lg:h-full lg:w-1/2 lg:flex-col lg:overflow-auto">
				{children}
				<footer className="hidden w-full justify-between bg-white p-8 text-sm text-[#6B6B6B] lg:flex">
					<div>© Izzy s.r.o.</div>
					<div className="inline-flex items-center gap-2">
						<img width={16} height={16} src="/img/mail.svg" alt="mail" />
						<a href="mailto:info@izzy.cz">info@izzy.cz</a>
					</div>
				</footer>
			</div>
			<div className="bg-login h-1/3 lg:h-full lg:w-1/2">
				<div className="flex h-full w-full flex-col items-center justify-center px-8 pb-6 lg:pb-24">
					<div className="mx-4 max-w-full text-white md:max-w-md lg:max-w-lg">
						<img
							width={147}
							height={24}
							src="/img/landing-label.svg"
							alt="IZZY"
							className="mb-2 hidden lg:block"
						/>
						<div className="text-[48px] font-extrabold leading-[56px]">
							S Izzy je úklid easy
						</div>
						<div className="mb-2 mt-6 hidden text-[18px] leading-[24px] lg:block">
							Jediná mobilní aplikace v ČR, přes kterou si bezpečně objednáte
							profesionální úklid po Praze ještě v ten den.
						</div>
						<div className="mt-6 flex flex-row flex-wrap gap-x-4 gap-y-2">
							<div className="flex -space-x-2 overflow-hidden">
								{[1, 2, 3, 4, 5].map(num => (
									<img
										key={num}
										src={`/img/cleaning-providers/provider${num}.webp`}
										alt={`Provider ${num}`}
										className="inline-block h-10 w-10 rounded-lg border-[1.5px] border-white"
									/>
								))}
							</div>
							<div className="inline-flex items-center gap-1">
								<img
									width={8}
									height={8}
									src="/img/activity-indicator.svg"
									alt="online"
								/>
								<div className="text-[16px] leading-[24px]">
									31+ paní na úklid
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
